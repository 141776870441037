import React from 'react'
import Trade from '../assets/trade.svg'
import Train from '../assets/train.svg'
import Profit from '../assets/profit.svg'

const About = () => {
  return (
    <div>
      <div className='about-banner'>
        <div className='about-banner-container'>
          <h1>About Us</h1>
        </div>
      </div>
      <div>
        <div style={{maxWidth: '960px', margin: '0 auto', paddingBottom: '1%', paddingTop: '1%'}}>
          <h1 style={{ textAlign: 'center', fontFamily: 'Poppins, sans serif', fontWeight: '500', color: '#414d64', marginBottom: '40px' }}>What happens on MarketWiz?</h1>
          <ul style={{ display: 'flex', listStyle: 'none', justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'flex-start', padding: 0 }}>
            {['Trade', 'Train', 'Profit'].map((item, index) => (
              <li key={index} style={{textAlign: 'center', width: '30%', minWidth: '250px', margin: '0 auto 20px'}}>
                <img src={[Trade, Train, Profit][index]} alt={item} style={{maxWidth: '100%', height: 'auto'}} />
                <h4 style={{fontSize: 20, fontWeight: 500, fontFamily: 'Poppins, sans serif', margin: '15px 0'}}>{item}</h4>
                <p style={{fontSize: 16, fontWeight: '400', color: '#677183', margin: 0, padding: '0 10px', fontFamily: 'Poppins, sans serif'}}>
                  {item === 'Trade' && 'Trade on events by setting your own price. Hedge and show off what your trading skills are like.'}
                  {item === 'Train' && 'We help you with stats and facts to help you train your trading skills. Behtar seekho, behtar karo.'}
                  {item === 'Profit' && 'You trade with money, and smart trading can mean you walk away with profits.'}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default About