import React, { useState } from 'react';
import '../App.css';
import Marketwiz from '../assets/marketwiz.png'
import { Link } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = () => {
    const whatsappURL = `https://wa.link/xcuqfu`;
    window.open(whatsappURL);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={Marketwiz} alt="Marketwiz Logo" />
      </div>
      <div className={`navbar-links ${isOpen ? 'active' : ''}`}>
        <li><Link to="/" onClick={() => setIsOpen(false)}>Home</Link></li>
        <li><Link to="/about" onClick={() => setIsOpen(false)}>About Us</Link></li>
        <li><Link to="/contact" onClick={() => setIsOpen(false)}>Contact Us</Link></li>
        <li>
          <button className="whatsapp-button" onClick={handleClick}>
            <FaWhatsapp className="whatsapp-icon" />
            <span style={{ fontFamily: 'Poppins, sans-serif', fontSize: '16px' }}>Get Your Free Trade</span>
          </button>
        </li>
      </div>
      <div className={`navbar-toggle ${isOpen ? 'active' : ''}`} onClick={toggleNavbar}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </nav>
  );
};

export default Navbar;