import React from 'react';
import { Link } from 'react-router-dom';
import Marketwiz from '../assets/marketwiz.png';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-container'>
        <div className='footer-content'>
          <div className='footer-logo'>
            <img src={Marketwiz} alt="Marketwiz Logo" />
          </div>
          <nav className='footer-nav'>
            <ul>
              <li>
                <Link to="/about">How it works?</Link>
              </li>
              {/* Add more footer links as needed */}
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;