import React from 'react'
import '../App.css'

const Contact = () => {
  return (
    <div>
      <div className='contact-banner'>
        <div className='contact-banner-container'>
          <h1>Contact Us</h1>
        </div>
      </div>

      <div className='contact-us-section'>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
          <div style={{ flexGrow: 0, marginBottom: '20px' }}>
            <h1 style={{ fontFamily: 'Poppins, sans-serif', fontWeight: '500', fontSize: 'clamp(32px, 5vw, 40px)' }}>Let's Connect!</h1>
          </div>
          <div style={{ flexGrow: 1, height: '3px', backgroundColor: 'black' }}></div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', maxWidth: '1200px', margin: '0 auto' }}>
          <div style={{ marginBottom: '30px', width: '100%' }}>
            <p style={{ fontFamily: 'Poppins, sans-serif', fontSize: 'clamp(16px, 2.5vw, 18px)' }}>Yes, let's get in touch!</p>
            <p style={{ fontFamily: 'Poppins, sans-serif', fontSize: 'clamp(16px, 2.5vw, 18px)' }}>Feel free to contact us at our email address</p>
            <p style={{ fontFamily: 'Poppins, sans-serif', fontSize: 'clamp(16px, 2.5vw, 18px)' }}>
              <a style={{ fontFamily: 'Poppins, sans-serif', color: '#6041b0', fontWeight: '600' }} href="mailto:navyanshkesarwani@gmail.com">navyanshkesarwani@gmail.com</a>
            </p>
          </div>
          <div style={{ width: '100%', maxWidth: '500px' }}>
            <form action="">
                <div className='form-group'>
                  <label htmlFor="name" className='contact-form-label'>Name</label>
                  <input type="text" name="name" id="name" className='contact-form-input' placeholder='Enter your name here' />
                </div>
                <div className='form-group'>
                  <label htmlFor="email" className='contact-form-label'>Email</label>
                  <input type="email" name="email" id="email" className='contact-form-input' placeholder='Enter your email here' />
                </div>
                <div className='form-group'>
                  <label htmlFor="phone-number" className='contact-form-label'>Phone Number</label>
                  <input type="tel" name="phone-number" id="phone-number" className='contact-form-input' placeholder='Enter your phone number here' />
                </div>
                <div className='form-group'>
                  <label htmlFor="message" className='contact-form-label'>Message</label>
                  <textarea type="text" name="message" id="message" className='contact-form-input' style={{fontSize: '16px', padding: '14px 16px', height: 'auto'}} placeholder='Type your message here' rows={10} />
                </div>
                <div style={{marginTop: '40px'}}>
                <button type="submit" className='submit-btn'>Send</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact